import React from 'react';
import Layout from '../../components/layout';
import { Box, Grid, Typography } from '@mui/material';
import whiteHome from '../../images/white home.jpg'
const AboutUs = () => {
    return (
        <Layout>
            <Grid container  alignItems={'center'} sx={{padding:'1em',flexWrap:'wrap-reverse'}}>
                <Grid md={6} lg={6} xs={12}>
                <Box className="aboutUs" textAlign={'center'} >
            <Typography variant='h1'>About our Company</Typography>
           
            <Typography variant='h3'>Founded in 2022 in Dhaka Bangladesh, SCAB BD is a fast growing      company
                that specializes in providing affordable, but high-quality services to customers
                that value natural surroundings and want to embellish their houses or offices with
                beautiful environment. We are a renowned construction company with a strong
                commitment to delivering quality, innovation, and excellence in every project we
                undertake. At SCAB BD, we understand that construction is more than just bricks
                and mortar; it's about turning your dreams into reality. Our company was founded
                on the principles of integrity, dedication, and a passion for creating spaces that
                inspire and endure.</Typography>
                <Typography variant='h3'><b>Mission </b>:  To deliver high-quality construction solutions that exceed
client expectations, foster innovation, and contribute to the sustainable
development of communities.<br></br>
<b>Vision </b> : To be the leading construction company in our region,
known for our commitment to excellence, innovation, and social responsi</Typography>
            </Box>
                </Grid>
                <Grid md={6} lg={6} xs={12}>
                    <img style={{width:'100%'}} src={whiteHome} alt='something'/>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default AboutUs;