import React from 'react';
import {Box,Typography} from '@mui/material'
import './clientsCard.css'
import { Image } from 'react-bootstrap';


const ClientsCard = ({ name, logoSrc, description }) => {
  return (
 
      <Box className="card" textAlign={'center'} >
      <Image className='cardImage' src={logoSrc} alt={name}/>
      <Typography  variant='h4'>{name}</Typography>
      </Box>

     
   

  )
};

export default ClientsCard;