import * as React from 'react';
import { Box, IconButton, useTheme } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";


const pages = [{ name: "Services", path: "/services" }, { name: "Clients", path: "/clients" }, { name: "Projects", path: "/projects" }, { name: "Management", path: "/associates" }, { name: "About Us", path: "/about" }, { name: "Contact Us", path: "/contact" },];

const ResponsiveAppBar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
   

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    return (
        <AppBar sx={{backgroundColor:` ${colors.primary[500]}`}} position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* logo Expaned */}
                    <Link style={{ textDecoration: 'none' }} to='/'>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                flexGrow: 3,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',


                            }}
                        >
                            SCABBD
                        </Typography>
                    </Link>

                    {/* menu on expand */}
                    <Box sx={{ flexGrow: 5, justifyContent: 'center', display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
                        {pages.map((page) => (
                            <Link style={{ textDecoration: 'none' }} to={page.path}>
                                <Button
                                    key={page.name}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, mx: 1, color: 'white', display: 'block' }}
                                >
                                    {page.name}
                                </Button>
                            </Link>

                        ))}
                    </Box>

                    {/* menubar collapesed */}
                    <Box sx={{ flexGrow: 3, display: { xs: 'flex', md: 'none' } }}>
                        {/* menubar */}
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* menu bar on collased */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Link style={{ textDecoration: 'none' }} to={page.path}> <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem></Link>
                            ))}
                        </Menu>
                    </Box>

                    {/* logo on collased */}
                    <Link style={{ textDecoration: 'none' }} to='/'>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{

                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 8,
                                justifyContent: 'center',
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            SCAB BD
                        </Typography>
                    </Link>

                    {/* // dark mode night mode button box  */}
                    <Box >
                        <IconButton onClick={colorMode.toggleColorMode}>
                            {theme.palette.mode === "dark" ? (
                                <DarkModeOutlinedIcon /> 

                            ) : (
                                <LightModeOutlinedIcon />

                            )}
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;