import React from 'react';
import Layout from '../../components/layout';
import SliderBanner from '../../components/slider';
import './index.css'
import Services from '../../components/services';
import Intro from '../../components/introSection/introSection';
import Projects from '../../components/projects/Projects';
import Associates from '../../components/associates/Associates';
import Clients from '../../components/clients/Clients';



const Home = () => {

    return (
        <Layout>
            
                <SliderBanner />
                <Intro />
                <Services />
                <Clients/>
                <Projects/>
               <Associates/>
           
        </Layout>

    );
};

export default Home;