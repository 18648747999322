import director from '../../images/Director.jpeg'
import managingDirector from '../../images/Managing Director.jpeg'
import chairman from '../../images/Chairman.jpeg'
import viceChairman from '../../images/Vice Chairman.jpeg'



export const associatesData = [
  {
    "Name": "Shaikh Maksud Ali ",
    "Designation": "Chairman",
    "Picture": chairman
  },
  {
    "Name": "AB Mainuddin Akon ",
    "Designation": "Vice Chairman",
    "Picture": viceChairman
  },
     {
      "Name": "Md. Salahuddin Sharif Jewel ",
      "Designation": "Managing Director",
      "Picture": managingDirector
    },
    
    {
      "Name": "Maj (Retd) Abdullah Al Mamun ",
      "Designation": "Director",
      "Picture": director
    },
    
  ]
  