import { Typography ,Box} from '@mui/material';
import React from 'react';
import './Projects.css'
import {completedProjects,ongoingProjects} from './ProjectsData'

const Projects = () => {
    return (
        <Box sx={{margin:'0.5em 0em '}}>
            <Typography variant='h2' textAlign='center'>
            Our Projects
            </Typography>
            <Typography variant='h3' textAlign='center'>Completed Projects</Typography>

            <Box sx={{display:'flex'}} >
              {completedProjects.map(project=>(
              <Box className='container' sx={{marginLeft:'1em',}}  >
                <img className='completedImg' src={project.picture} alt={project.name} />
                <Box className="middle">
                        <Typography className='text'>
                            See Details
                        </Typography>
                </Box>
                <Typography>
                    {project.location}
                </Typography>
              </Box>))}
            </Box>

            <Typography variant='h3' textAlign='center'>Ongoing Projects</Typography>

<Box sx={{display:'flex'}} >
  {ongoingProjects.map(project=>(
  <Box className='container' sx={{marginLeft:'1em',}}  >
    <img className='completedImg' src={project.picture} alt={project.name} />
    <Box className="middle">
            <Typography className='text'>
                See Details
            </Typography>
    </Box>
    <Typography>
        {project.location}
    </Typography>
  </Box>))}
</Box>
        </Box>
    );
};

export default Projects;