import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {Typography,Grid, Avatar} from '@mui/material'
import {associatesData} from './AssociatesData'
import './Associates.css'
import {Container} from '@mui/system';
import MDImage from '../../images/Managing Director.jpeg'
import chairmenImg from "../../images/Chairman.jpeg"
import director from "../../images/Director.jpeg"



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


const Associates = () => {
    return (
       <Container sx={{marginTop:"10vh", marginBottom:'5vh'}} >
         <Grid sx={{paddingTop:"1vh", paddingBottom:'5vh',alignItems:'center',justifyContent:'center'}} container >
         <Grid lg={6} md={4} sm={12} xs={12}><Avatar sx={{width:226,height:250,marginLeft:'2.5em'}} src={chairmenImg} alt='Chairmen Img'/></Grid>
           <Grid lg={6} md={8} sm={12} xs={12}  >
           <Typography variant="h2" textAlign='left'>Message from The Chairmen</Typography>
           <Typography sx={{paddingTop:'1.8em'}} variant={'h3'}>" The Chairmen of SCAB BD is a known as a perspective business entrepreneur in real estate business and building technology in Bangladesh. With his imaginative leadership and business knowledge, Life is work and work is life. The meaning of life is speed. To run briskly is to live, to stand still is to die. Those who don't run and survive may die and survive. In my life I tried my best to complete every task and never neglected any task. By the blessings of Allah's infinite mercy, I have been able to do almost all the things that I have tried to do on the way and what has made me the most cheerful is being by the side of the helpless. Bill Gates Quote “Once I failed a few subjects in an exam but my friend passed everything. Now he is an engineer at Microsoft and I am the founder of Microsoft.” However, finally through the collective talent and efforts of all of us. In closing, I am confident that with the collective efforts of our talented team, SCAB BD will continue to thrive as a leading force in the construction industry. Together, we will build a future that is marked by innovation, integrity, and lasting success. InshaAllah...
Thank you for your continued dedication and hard work."
</Typography>
           </Grid>
          
        </Grid>
      
        <Grid sx={{paddingTop:"1vh", paddingBottom:'5vh',alignItems:'center',justifyContent:'center'}} container >
           <Grid lg={6} md={8} sm={12} xs={12}  >
           <Typography variant="h2" textAlign='center'>Message from Managing Director</Typography>
            <Typography sx={{paddingTop:'1.8em'}} variant={'h3'}>" Hello, and welcome to our construction company! As the managing director, I'm here to ensure that our projects are carried out with excellence and to meet our clients' expectations. If you have any questions, concerns, or ideas, please feel free to reach out to me. We're committed to delivering high-quality construction services and creating lasting structures that stand the test of time. Thank you for choosing our company for your construction needs, and I look forward to working with you."
</Typography>
           </Grid>
           <Grid lg={6} md={4} sm={12} xs={12}><Avatar sx={{width:226,height:250,marginLeft:'2.5em'}} src={MDImage} alt='managing director'/></Grid>
        </Grid>
        <Grid sx={{paddingTop:"1vh", paddingBottom:'5vh',alignItems:'center',justifyContent:'center'}} container >
        <Grid lg={6} md={4} sm={12} xs={12}><Avatar sx={{width:226,height:250,marginLeft:'2.5em'}} src={director} alt='director'/></Grid>
           <Grid lg={6} md={8} sm={12} xs={12}  >
           <Typography variant="h2" textAlign='center'>Message from  Director</Typography>
            <Typography sx={{paddingTop:'1.8em'}} variant={'h3'}>"At SCAB BD, we believe in the transformative power of construction. As the Director of this esteemed company, I am proud to lead a team that is dedicated to building not just structures, but enduring relationships and communities. Our mission is clear: to deliver exceptional construction services with unwavering commitment to quality, safety, and sustainability. We understand that the structures we build today will shape the world of tomorrow, and we embrace the responsibility that comes with it."
</Typography>
           </Grid>
           
        </Grid>
        <Typography variant="h2" textAlign='center'>Associates</Typography>
         <TableContainer maxWidth={'xl'} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableBody>
            {associatesData.map((associate) => (
              <StyledTableRow
                key={associate.Name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell  scope="row">
                  <Typography variant='h5' >{associate.Name}</Typography>
                </StyledTableCell>
                <StyledTableCell  scope="row">
                  <Typography variant='h5' >{associate.Designation}</Typography>
                </StyledTableCell>
                <StyledTableCell >
                    <Avatar sx={{width:130,height:150,marginLeft:'2.5em'}} src={associate.Picture} alt={associate.Name}/>
                    </StyledTableCell>          
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
       </Container>
    );
};

export default Associates;