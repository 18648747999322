import HighRiser from '../../images/HighRiser.jpeg'
import Duplex from '../../images/Duplex.jpeg'
import Ongoing1 from '../../images/ongoing1.jpeg'
import Ongoing2 from '../../images/ongoing2.jpeg'

export const completedProjects =[
    {
        "status": "Completed",
        "name": "High Riser",
        "picture": HighRiser ,
        "location": "Chittagong",
        "details": "Exclusive High Riser Builing"
      },
      {
        "status": "Completed",
        "name": "Duplex",
        "picture": Duplex,
        "location": "Faridpur",
        "details": "Luxarious Duplex Building."
      }
]
export const ongoingProjects = [

    {
      "status": "Ongoing",
      "name": "Resort",
      "picture": Ongoing1,
      "location": "Kuril Bishwa Road,Dhaka",
      "details": "A Wonderful Resort inside the capital city"
    },
    {
        "status": "Ongoing",
        "name": "Resort",
        "picture": Ongoing2,
        "location": "Kuril Bishwa Road,Dhaka",
        "details": "A Wonderful Resort inside the capital city"
      }
  ]
  