import React from 'react';
import { Box, Typography,Grid } from '@mui/material';
import bulding from '../../images/bxbl_vp8n_230116.jpg'
import {Image} from "react-bootstrap"


const Intro = () => {
    return (
        <Box textAlign={'center'} sx={{marginTop:'1em',padding:'2em'}}>
            
            <Grid  container  alignItems={'center'} justifyContent={'space-between'} >
                <Grid xs={12} md={5} lg={6} >
                    <Typography variant="h3">Hello, and welcome to our construction company! As the managing director,
I'm here to ensure that our projects are carried out with excellence and to
meet our clients' expectations. If you have any questions, concerns, or ideas,
please feel free to reach out to me. We're committed to delivering high-quality
construction services and creating lasting structures that stand the test of
time. Thank you for choosing our company for your construction needs, and I
look forward to working with you.
                </Typography>
                </Grid>
                <Grid  xs={12}  md={5} lg={5}>
                    <Image style={{maxWidth:"100%" ,marginTop:'2em'}} src={bulding} alt="sdf" fluid  />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Intro;