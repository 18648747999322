import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography,Container} from '@mui/material';
import './sliderbanner.css'
import { sliderData } from './sliderData';
import Image from 'react-bootstrap/Image';




const SliderBanner = () => {
    const settings = {
        dots:true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed:1500,
        arrows:false,
      };
    return (
       <Container  disableGutters={true}	  >
        <Slider  {...settings}>
          {sliderData.map((slide)=>(<Box className={'container'}  >
           <Image  src={slide.imageUrl} alt="slider"  fluid />
           <Typography color={'red'} variant={'h1'} className='centered' >{slide.title}</Typography>
           </Box>))}
        </Slider>
    
        </Container>
     
       
    );
};

export default SliderBanner;