import React from 'react';
import Layout from '../../components/layout';
import Services from '../../components/services';

const ServicesPage = () => {
    return (
        <Layout>
            <Services/>
        </Layout>
    );
};

export default ServicesPage;