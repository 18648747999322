import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import PlumbingOutlinedIcon from '@mui/icons-material/PlumbingOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import SouthAmericaOutlinedIcon from '@mui/icons-material/SouthAmericaOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
export const  servicesData = [
    {
      "name": "Rajuk Plan Approval",
      "icon": <AppRegistrationOutlinedIcon sx={{fontSize:"5.5em"}}/>
    },
    {
      "name": "Interior & Exterior Design",
      "icon": <MeetingRoomOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Soil Test & Pile Construction",
      "icon": <ConstructionOutlinedIcon sx={{fontSize:"5.5em"}}/>
    },
    {
      "name": "Architecture  Drawing",
      "icon": <DrawOutlinedIcon sx={{fontSize:"5.5em"}}/>
    },
    {
      "name": "Structural Design",
      "icon":< DesignServicesOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Electrical Design",
      "icon": <ElectricalServicesOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Plumbing Design",
      "icon":< PlumbingOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Landscaping",
      "icon":< LandscapeOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
   
    {
      "name": "Land Survey",
      "icon": <SouthAmericaOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Estimation",
      "icon": <CalculateOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Construction",
      "icon": <ConstructionOutlinedIcon sx={{fontSize:'5.5em'}}/>
    },
    {
      "name": "Retrofitting",
      "icon": <ReplayOutlinedIcon sx={{fontSize:'5.6em'}}/>
    },
   
  ]