import React from 'react';
import Layout from '../../components/layout';
import Clients from '../../components/clients/Clients';

const ClientsPage = () => {
    return (
        <Layout>
            <Clients/>
        </Layout>
    );
};

export default ClientsPage;