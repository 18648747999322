import React from 'react';
import { clientsData } from './clientsData';
import ClientsCard from './ClientsCard';
import {Box,Container,Typography,} from '@mui/material'


const Clients = () => {
    return (
        <Box sx={{backgroundColor:'lightgray',borderRadius:'1em'}} >
        <Typography variant='h2' textAlign='center'>Our Clients</Typography>
        <Container sx={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:"center"}} maxWidth='xl'>
          {clientsData.map((client) => (
            <ClientsCard key={client.id} {...client} />
          ))}
        </Container>
        

        
      </Box>
    );
};

export default Clients;