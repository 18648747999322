import React from 'react';
import { servicesData } from './servicesData';
import { Grid,Box, Container,Typography } from '@mui/material';

const Services = () => {
    return (
        <Container sx={{minHeight:'90vh',marginTop:'3vh'}}>
            <Typography textAlign={'center'} variant={'h2'}>
                Our Services
            </Typography>
        <Grid  container alignItems={'center'} spacing={1}>
            {servicesData.map((service)=>(
           <Grid  lg={4} md={6} xs={12}>
             <Box alignItems={'center'} justifyContent={'center'} sx={{display:'flex'}}>
                <Typography >
                {service.icon}
                  </Typography>
                <Typography sx={{marginLeft:'0.5em'}}>{service.name}</Typography>
            </Box>
           </Grid>))}
        </Grid>
        </Container>
    );
};

export default Services;