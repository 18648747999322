import React from 'react';
import Layout from '../../components/layout';
import { Box, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const ContactsUs = () => {
    return (
        <Layout>
            <Box sx={{ minHeight: '90vh' }}>
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><Typography><LocationOnOutlinedIcon/></Typography>
                <Typography> Gausul Azam Avenue,
                    House :61
                    Sector: 14
                    Uttara, Dhaka-1230</Typography></Box>
                    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><PhoneAndroidOutlinedIcon/> <Typography></Typography>
                    <Typography>01819820733, 01689150311</Typography></Box>
                    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}><Typography><EmailOutlinedIcon/></Typography>
                    <Typography>rolmar.bd@gmail.com</Typography></Box>
                   
            </Box>
        </Layout>
    );
};

export default ContactsUs
