import blackBuilding from '../../images/black building.jpg';
import interior from '../../images/interior.jpg';
import swimmingPool from '../../images/swmining pool.jpg';
import whiteBuilding from '../../images/white building.jpg';
export const sliderData = [
    {
      "title": "Remarkable Commercial Building",
      "imageUrl": blackBuilding,
      "link": "Services"
    },
    {
      "title": "Beautiful Ineterior",
      "imageUrl": interior,
      "link": "Clients"
    },
    {
      "title": "Swimming Pool",
      "imageUrl": swimmingPool,
      "link": "About us"
    },
    {
      "title": "Luxirous High Riser Builing",
      "imageUrl": whiteBuilding,
      "link": "SomeWhere"
    }
  ]
  