import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import ProjectsPage from "./pages/projects/ProjectsPage"
import ServicesPage from "./pages/servicesPage/ServicesPage";
import ClientsPage from "./pages/clients/ClientsPage";
import AssociatesPage from "./pages/associatesPage/AssociatesPage";
import AboutUs from "./pages/aboutusPage/AboutUs";
import ContactsUs from "./pages/contactUsPage/ContactsUs";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";



function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/> 
      <Route path="/services" element={<ServicesPage/>}/> 
      <Route path="/clients" element={<ClientsPage/>}/> 
      <Route path="/associates" element={<AssociatesPage/>}/> 
      <Route path="/about" element={<AboutUs />}/> 
      <Route path="/contact" element={<ContactsUs />}/> 

      



      <Route path="/projects" element={<ProjectsPage/>}/>
      
    
    </Routes>
  </BrowserRouter>  
  </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
