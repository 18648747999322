import React from 'react';
import ResponsiveAppBar from '../appBar';
import Footer from '../footer';

const Layout = ({ children }) => {
    return (
        <body>
            <ResponsiveAppBar />
                    {children}
            <Footer />
        </ body>

    );
};

export default Layout;