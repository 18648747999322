import React from 'react';
import Box from '@mui/material/Box';



const Footer = () => {

  return (
    <Box textAlign={'center'} sx={{ backgroundColor: '#3c76d2', color: '#fff', minHeight: '10vh', }}>
      <footer >
        <div>
          {/* Footer content goes here */}
          <p >&copy; 2023 SCABBD copyright reserved</p>
        </div>
      </footer>
    </Box>
  );
};

export default Footer