import React from 'react';
import Layout from '../../components/layout';
import Projects from '../../components/projects/Projects';

const ProjectsPage = () => {
    return (
        <Layout>
            <Projects/>
        </Layout>
    );
};

export default ProjectsPage;