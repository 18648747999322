import React from 'react';
import Layout from '../../components/layout';
import Associates from '../../components/associates/Associates';

const AssociatesPage = () => {
    return (
        <Layout>
            <Associates/>
        </Layout>
    );
};

export default AssociatesPage;